import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Import environment
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getAll = (params: any) => {
    return this.http.get(environment.userApiUrl + 'api/admin/user', { params })
  }

  disable2FA = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/admin/disable-2fa', data)
  }

  userActivate = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/admin/userActivate', data)
  }

  resendMailApi = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/admin/resendMail', data)
  }

  userLockApi = (data: any) => {
    return this.http.put(environment.userApiUrl + 'api/admin/user-locked', data)
  }

  getUserAssetApi = (params: any) => {
    return this.http.get(environment.walletApiUrl + 'api/admin/getUserAsset', { params })
  }

  getPassbookList = (params: any) => {
    return this.http.get(environment.walletApiUrl + 'api/admin/getpassbook', { params })
  }

  getUserPassbookList = (params: any) => {
    return this.http.get(environment.walletApiUrl + 'api/admin/userPassBookHistory', { params })
  }


  updateAsset = (data: any) => {
    return this.http.put(environment.walletApiUrl + 'api/admin/updateUserAsset', data)
  }

  getAllKyc = (params: any) => {
    return this.http.get(environment.userApiUrl + 'api/admin/userKyc', { params })
  }
  getBank = (params: any) => {
    return this.http.get(environment.userApiUrl + 'api/admin/getBankData', { params })
  }

  findByBankId = (id: string, userId: string) => {
    return this.http.get(environment.userApiUrl + 'api/admin/getBanksingle/' + id + "/" + userId)
  }

  updateBank = (data: any) => {
    return this.http.put(environment.userApiUrl + 'api/admin/updateBank', data)
  }

  deleteBank = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/admin/updateBank', data)
  }
  updateBankstatus = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/admin/updateBankstatus', data)
  }





}
