import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Import environment
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AnnouncementService {

    constructor(private http: HttpClient) { }

    create = (data: any) => {
        return this.http.post(environment.userApiUrl + 'api/admin/announcement', data)
    }

}
