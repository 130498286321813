<div class="bg-light d-flex flex-row align-items-center">
    <c-container>
        <c-row class="justify-content-center">
            <!-- <c-col md="6">
          <span class="clearfix">
            <h1 class="float-start display-3 me-4">500</h1>
            <h4 class="pt-3">Houston, we have a problem!</h4>
            <p class="text-medium-emphasis float-start">
              The page you are looking for is temporarily unavailable.
            </p>
          </span>
          <c-input-group class="input-prepend">
            <span cInputGroupText>
              <svg cIcon name="cilMagnifyingGlass"></svg>
            </span>
            <input cFormControl placeholder="What are you looking for?" type="text" />
            <button cButton color="info">Search</button>
          </c-input-group>
        </c-col> -->
            <c-col>
                <h4 class="display-3 pt-3">Access Denied!</h4>
                <p class="text-medium-emphasis float-start">
                    You don't have permission to access this page.
                </p>
            </c-col>
        </c-row>
    </c-container>
</div>