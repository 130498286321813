import { createFeatureSelector, createSelector } from '@ngrx/store';

import { roleFeatureKey } from './roles.reducers';

// Import model
import { Role } from './roles.model';

export const selectRoleState = createFeatureSelector<Role>(roleFeatureKey);

export const selectRole = createSelector(selectRoleState, (state: Role) => state);
