import { createAction, props } from '@ngrx/store';

import { Role } from './roles.model'

const prefix = '[ROLE]';

// export const setRole = createAction(`${prefix} Set Role`);

export const setRole = createAction(
  `${prefix} Set Role`, props<{ data: Partial<Role>; }>()
);

export const remove = createAction(
  `${prefix} Remove Role`
);