import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

// Import service
import { ForgotPasswordService } from './services/forgot-password.service'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit {
  isLoader: boolean = false
  form: FormGroup;
  serverErrors: any;

  constructor(
    private service: ForgotPasswordService,
    private toastr: ToastrService,
    private router: Router,
    private _fb: FormBuilder,
  ) {
    this.form = this._fb.group({
      email: ['']
    });
  }

  ngOnInit() {
  }

  submitForm() {
    this.isLoader = true
    const reqBody = {
      ...this.form.value
    }

    this.service.forgotPasswordApi(reqBody)
      .subscribe({
        next: (v: any) => {
          this.isLoader = false
          if (v.success) {
            // this.router.navigateByUrl('/dashboard')
            this.toastr.success(v.message)
            this.form.reset()
            this.serverErrors = {}
          }
        },
        error: (err: any) => {
          this.isLoader = false
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
  }

  onInputChange() {
    this.serverErrors = {}
  }

  redirectToPage(str: string) {
    this.router.navigateByUrl(str)
  }
}