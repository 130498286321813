import { Component, ViewChild } from '@angular/core';

// Import service
import { LaunchpadService } from '../../services/launchpad.service';

// Import models
import {
  PeriodicElementToken,
  QueryParams,
  Sorting,
} from '../../models/launchpad.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

// Import lib
import { encryptString } from 'src/app/lib/cryptoJs';

@Component({
  selector: 'app-purchase-token-history',
  templateUrl: './purchase-token-history.component.html',
  styleUrls: ['./purchase-token-history.component.scss']
})
export class PurchaseTokenHistoryComponent {
  dataSource: MatTableDataSource<PeriodicElementToken> = new MatTableDataSource();
  displayedColumns: string[] = ['createdAt', 'launchAmount', 'coin', 'launchCoin', 'price', 'convertPrice', 'commit'];
  isLoading: boolean = false;

  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];

  sorting: Sorting = {};

  // statusOptions: string[] = ['all', 'active', 'deactive'];
  // selectedStatus: string = 'all';

  // typeOptions: string[] = ['all', 'crypto', 'token', 'fiat'];
  // selectedType: string = 'all';

  queryParams: QueryParams = {
    fillter: '{}',
    sortObj: '{}',
    page: this.currentPage + 1,
    limit: this.pageSize,
  };

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  constructor(private service: LaunchpadService, private router: Router) { }

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getData(): void {
    this.isLoading = true;
    this.service.purchaseTokenHistoryApi(this.queryParams).subscribe({
      next: (v: any) => {
        this.dataSource = v.result.data;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = v.result.count;
        });
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
      },
    });
  }

  onInputChange(event: Event, column: string) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.updateFilter(column, filterValue);
  }

  onDateChange(event: any, column: string) {
    if (!event.value)
      this.updateFilter(column, '');

    const selectedDate: string = event.value.toISOString();
    this.updateFilter(column, selectedDate);
  }

  onOptionChange(event: any, column: string) {
    const filterValue = (event.source as HTMLSelectElement).value;
    this.updateFilter(column, filterValue);
  }

  pageChanged(event: PageEvent) {
    this.queryParams.page = event.pageIndex + 1;
    this.queryParams.limit = event.pageSize;
    this.getData();

    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  sortData(column: string) {
    if (this.sorting.column === column) {
      if (this.sorting.direction === 1) {
        this.sorting.direction = -this.sorting.direction;
      } else if (this.sorting.direction === -1) {
        this.sorting = {};
      }
    } else {
      this.sorting.column = column;
      this.sorting.direction = 1;
    }
    let sortObj = {};
    if (this.sorting.column) {
      sortObj = { [column]: this.sorting.direction };
    }
    this.queryParams.sortObj = JSON.stringify(sortObj);
    this.getData();
  }

  updateFilter(column: string, filterValue: string) {
    const numFields = ['price', 'convertPrice', 'launchAmount']
    const filterKey = column === 'createdAt' ? 'fd_' + column : column === 'commit' ? 'fid_' + column : numFields.includes(column) ? 'fn_' + column : 'fs_' + column;

    const filterObj = JSON.parse(this.queryParams.fillter || '{}');
    if (filterValue !== '') {
      filterObj[filterKey] = filterValue;
    } else {
      delete filterObj[filterKey];
    }
    this.queryParams.fillter = JSON.stringify(filterObj);
    this.getData();
  }

  redirectToAdd() {
    this.router.navigateByUrl('/launchpad/add');
  }

  redirectToEdit(id: string) {
    this.router.navigateByUrl('/launchpad/edit/' + encryptString(id, true));
  }
}
