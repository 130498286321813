import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { detect } from 'detect-browser';

// Import environment
import { environment } from '../../../environments/environment'
import { Router } from '@angular/router';

// Import service
import { AuthService } from './services/auth.service'
import { setRole } from 'src/app/store/roles';
// import { addMessage } from 'src/app/store/messages';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  // private readonly store: Store = inject(Store);
  isLoader: boolean = false
  showPassword: boolean = false
  form: FormGroup;
  serverErrors: any;
  loginHistory: any = {}

  loaders: any = false
  otpToken: string = ''
  otpTextBox: boolean = false;
  otpVerified: boolean = false;
  seconds: number = 60;
  timerInSeconds: number = 60;
  isOtpSend: boolean = false;

  twoFATextBox: boolean = false;

  constructor(
    private cookieService: CookieService,
    private service: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private store: Store<any>,
    private _fb: FormBuilder,
  ) {
    this.form = this._fb.group({
      email: [''],
      password: [''],
      otp: [''],
      twoFACode: ['']
    });
  }

  ngOnInit() {
    this.getGeoLocation()
  }

  submitForm() {
    this.isLoader = true
    const reqBody = {
      ...this.form.value,
      loginHistory: this.loginHistory,
      isOtpSend: this.isOtpSend
    }
    // console.log(reqBody, 'reqBody')
    // return

    this.service.loginApi(reqBody)
      .subscribe({
        next: (v: any) => {
          this.isLoader = false
          if (v.status == 'OTP') {
            this.otpTextBox = true
            this.otpToken = v.userToken
            this.loaders = true
            this.timer(this.timerInSeconds)
            this.isOtpSend = true
            this.toastr.success(v.message)
          } else if (v.status === 'TWO_FA') {
            this.otpVerified = true
            this.twoFATextBox = true
            this.toastr.success(v.message)
          } else if (v.status == "SUCCESS") {
            // this.isLoader = false
            this.cookieService.set('admin_token', v.token, { path: '/' })
            this.router.navigateByUrl('/dashboard')
            this.toastr.success(v.message)
            this.store.dispatch(setRole({ data: v.result }));
            this.form.reset()
            this.serverErrors = {}
          }
        },
        error: (err: any) => {
          this.isLoader = false
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
  }

  onInputChange() {
    this.serverErrors = {}
  }

  getGeoLocation() {
    const browser = detect();
    fetch('https://ipapi.co/json/')
      .then((response) => response.json())
      .then((v) => {
        this.loginHistory = {
          countryName: v.country_name,
          countryCode: v.country_calling_code,
          ipaddress: v.ip,
          region: v.region,
          broswername: browser?.name,
          ismobile: false,
          os: browser?.os
        }
      })
      .catch((error) => console.log(error));
  }

  timer(seconds: number) {
    this.seconds = seconds;
    const timer = setInterval(() => {
      this.seconds--;
      if (this.seconds == 0) {
        this.loaders = false
        clearInterval(timer);
      }
    }, 1000);
  }

  resendOtp() {
    const reqBody = {
      otpAuth: this.otpToken
    }
    this.service.resendOtpApi(reqBody)
      .subscribe({
        next: (v: any) => {
          this.loaders = true
          this.timer(this.timerInSeconds)
          this.toastr.success(v.message)
        },
        error: (err) => { }
      })
  }
  hanldeShowPassword() {
    this.showPassword = !this.showPassword
  }
  
  redirectToPage(str: string) {
    this.router.navigateByUrl(str)
  }
}