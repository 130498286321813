import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: 'input[decimals]'
})
export class DecimalsDirective {

    constructor(private _el: ElementRef) { }

    // @HostListener('input', ['$event'])

    // onInputChange(event: any) {
    //     const initalValue = this._el.nativeElement.value;
    //     this._el.nativeElement.value = initalValue.replace(/[^0-9].[^0-9]/g, '');
    //     if (initalValue !== this._el.nativeElement.value) {
    //         event.stopPropagation();
    //     }
    // }

    @HostListener('input', ['$event'])
    onInputChange(event: any) {
        let initalValue: string = this._el.nativeElement.value;
        initalValue = initalValue.replace(/[^\.|0-9]/g, '');
        // elimina le seconde occorrenze del punto
        const count = (initalValue.match(/\./g) || []).length;
        for (let i = 1; i < count; i++) {
            initalValue = this.repaceSecondDotOccurrence(initalValue);
        }
        this._el.nativeElement.value = initalValue;
    }

    repaceSecondDotOccurrence(inputString: string) {
        let t = 0;
        return inputString.replace(/\./g, function (match) {
            t++;
            return (t === 2) ? '' : match;
        });
    }


}