
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NumberDirective } from './numbersOnly.directives';
import { AlphaNumericDirective } from "./alphaNumeric.directives"
import { AlphaDirective } from "./alpha.directivies"

import { OnlyNumbersDirective } from "./OnlyNumbersDirective"
import { EmailDirective } from "./email.directives"
import { DecimalsDirective } from "./decimals.directive"


@NgModule({
    declarations: [NumberDirective, AlphaNumericDirective, AlphaDirective, OnlyNumbersDirective, EmailDirective, DecimalsDirective],
    imports: [CommonModule],
    exports: [NumberDirective, AlphaNumericDirective, AlphaDirective, OnlyNumbersDirective, EmailDirective, DecimalsDirective],
    providers: []
})


export class CoreModule { }