import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

// Import service
import { UserService } from '../../services/user.service';

// Import models
import { PeriodicElement, QueryParams, Sorting } from "../../models/user.model"
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-passbook-list',
  templateUrl: './passbook-list.component.html',
  styleUrls: ['./passbook-list.component.scss']
})
export class PassbookListComponent implements OnInit {

  dataSource: MatTableDataSource<PeriodicElement> = new MatTableDataSource()
  displayedColumns: string[] = ['createdAt', 'userCodeId', 'tableId', 'coin', 'beforeBalance', 'afterBalance', 'amount', 'type'];
  isLoading: boolean = false;

  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];

  sorting: Sorting = {};

  typeOption: string[] = [
    'all',
    'coin_deposit',
    'coin_withdraw',
    'fiat_deposit',
    'fiat_withdraw',
    'spot_limit_match',
    'spot_limit_orderPlace',
    'spot_limit_bal_retrieve',
    'spot_market_bal_retrieve_buy',
    'orderCancel',
    'spot_market_orderPlace'
  ];
  selectedType: string = 'all';

  queryParams: QueryParams = {
    fillter: '{}',
    sortObj: '{}',
    page: this.currentPage + 1,
    limit: this.pageSize
  };

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  constructor(
    private service: UserService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getData(): void {
    this.isLoading = true
    this.service.getPassbookList(this.queryParams).subscribe({
      next: (v: any) => {
        this.dataSource = v.data
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = v.count;
        });
        this.isLoading = false
      },
      error: (err) => {
        this.isLoading = false
      }
    });
  }

  onInputChange(event: Event, column: string) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.updateFilter(column, filterValue);
  }

  onOptionChange(event: any, column: string) {
    const filterValue = (event.source as HTMLSelectElement).value;
    this.updateFilter(column, filterValue);
  }

  onDateChange(event: any, column: string) {
    if (!event.value)
      this.updateFilter(column, '');

    const selectedDate: string = event.value.toISOString();
    this.updateFilter(column, selectedDate);
  }

  pageChanged(event: PageEvent) {
    this.queryParams.page = event.pageIndex + 1;
    this.queryParams.limit = event.pageSize;
    this.getData()

    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  sortData(column: string) {
    if (this.sorting.column === column) {
      if (this.sorting.direction === 1) {
        this.sorting.direction = -this.sorting.direction;
      } else if (this.sorting.direction === -1) {
        this.sorting = {};
      }
    } else {
      this.sorting.column = column;
      this.sorting.direction = 1;
    }
    let sortObj = {};
    if (this.sorting.column) {
      sortObj = { [column]: this.sorting.direction };
    }
    this.queryParams.sortObj = JSON.stringify(sortObj)
    this.getData()
  }

  updateFilter(column: string, filterValue: string) {
    const filterKey =
      column === '_id' ? 'fid_id' + column
        : column === 'beforeBalance' || column === 'amount' || column === 'afterBalance' ? 'fn_' + column
          : column === 'createdAt' ? 'fd_' + column
            : column === 'tableId' ? 'fid' + column
              : 'fs_' + column;

    const filterObj = JSON.parse(this.queryParams.fillter || '{}');
    if (filterValue !== '') {
      filterObj[filterKey] = filterValue;
    } else {
      delete filterObj[filterKey];
    }
    this.queryParams.fillter = JSON.stringify(filterObj);
    this.getData();
  }

}
