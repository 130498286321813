<div class="table-responsive">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 responsive-table">
        <ng-container matColumnDef="createdAt" *ngIf="!isLoading">
            <th mat-header-cell *matHeaderCellDef class="cursor-pointer">
                <div class="header-name d-flex align-items-center" (click)="sortData('createdAt')">
                    Purchase Date
                    <mat-icon class="sorting-icon" *ngIf="sorting.column === 'createdAt'">
                        {{ sorting.direction === 1 ? "arrow_upward" : "arrow_downward" }}
                    </mat-icon>
                </div>
                <mat-form-field>
                    <input matInput [matDatepicker]="picker" (dateInput)="onDateChange($event, 'createdAt')"
                        (dateChange)="onDateChange($event, 'createdAt')" placeholder="MM/DD/YYYY" />
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.createdAt | date:'medium' }}</td>
        </ng-container>
        <ng-container matColumnDef="launchAmount" *ngIf="!isLoading">
            <th mat-header-cell *matHeaderCellDef class="cursor-pointer">
                <div class="header-name d-flex align-items-center" (click)="sortData('launchAmount')">
                    Launch Amount
                    <mat-icon class="sorting-icon" *ngIf="sorting.column === 'launchAmount'">
                        {{ sorting.direction === 1 ? "arrow_upward" : "arrow_downward" }}
                    </mat-icon>
                </div>
                <mat-form-field appearance="outline" class="small-input"
                    (input)="onInputChange($event, 'launchAmount')">
                    <input matInput placeholder="Search" decimals />
                </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.launchAmount }}</td>
        </ng-container>

        <ng-container matColumnDef="coin" *ngIf="!isLoading">
            <th mat-header-cell *matHeaderCellDef class="cursor-pointer">
                <div class="header-name d-flex align-items-center" (click)="sortData('coin')">
                    Coin
                    <mat-icon class="sorting-icon" *ngIf="sorting.column === 'coin'">
                        {{ sorting.direction === 1 ? "arrow_upward" : "arrow_downward" }}
                    </mat-icon>
                </div>
                <mat-form-field appearance="outline" class="small-input" (input)="onInputChange($event, 'coin')">
                    <input matInput placeholder="Search" alphaNumeric />
                </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.coin }}</td>
        </ng-container>

        <ng-container matColumnDef="launchCoin" *ngIf="!isLoading">
            <th mat-header-cell *matHeaderCellDef class="cursor-pointer">
                <div class="header-name d-flex align-items-center" (click)="sortData('launchCoin')">
                    Launch Coin
                    <mat-icon class="sorting-icon" *ngIf="sorting.column === 'launchCoin'">
                        {{ sorting.direction === 1 ? "arrow_upward" : "arrow_downward" }}
                    </mat-icon>
                </div>
                <mat-form-field appearance="outline" class="small-input" (input)="onInputChange($event, 'launchCoin')">
                    <input matInput placeholder="Search" alphaNumeric />
                </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.launchCoin }}</td>
        </ng-container>

        <ng-container matColumnDef="price" *ngIf="!isLoading">
            <th mat-header-cell *matHeaderCellDef class="cursor-pointer">
                <div class="header-name d-flex align-items-center" (click)="sortData('price')">
                    Price
                    <mat-icon class="sorting-icon" *ngIf="sorting.column === 'price'">
                        {{ sorting.direction === 1 ? "arrow_upward" : "arrow_downward" }}
                    </mat-icon>
                </div>
                <mat-form-field appearance="outline" class="small-input" (input)="onInputChange($event, 'price')">
                    <input matInput placeholder="Search" decimals />
                </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.price }}</td>
        </ng-container>

        <ng-container matColumnDef="convertPrice" *ngIf="!isLoading">
            <th mat-header-cell *matHeaderCellDef class="cursor-pointer">
                <div class="header-name d-flex align-items-center" (click)="sortData('convertPrice')">
                    Convert Price
                    <mat-icon class="sorting-icon" *ngIf="sorting.column === 'convertPrice'">
                        {{ sorting.direction === 1 ? "arrow_upward" : "arrow_downward" }}
                    </mat-icon>
                </div>
                <mat-form-field appearance="outline" class="small-input"
                    (input)="onInputChange($event, 'convertPrice')">
                    <input matInput placeholder="Search" decimals />
                </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.convertPrice }}</td>
        </ng-container>

        <ng-container matColumnDef="commit" *ngIf="!isLoading">
            <th mat-header-cell *matHeaderCellDef class="cursor-pointer">
                <div class="header-name d-flex align-items-center" (click)="sortData('commit')">
                    Commit Id
                    <mat-icon class="sorting-icon" *ngIf="sorting.column === 'commit'">
                        {{ sorting.direction === 1 ? "arrow_upward" : "arrow_downward" }}
                    </mat-icon>
                </div>
                <mat-form-field appearance="outline" class="small-input" (input)="onInputChange($event, 'commit')">
                    <input matInput placeholder="Search" alphaNumeric />
                </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.commit }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ 'hide-data': isLoading }"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="9999" class="p-4">
                <strong class="text-secondary">No record found!</strong>
            </td>
        </tr>
    </table>
    <div *ngIf="isLoading" class="spinner-container">
        <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
        </mat-progress-spinner>
    </div>
</div>
<mat-paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons aria-label="Select page of periodic elements" (page)="pageChanged($event)">
</mat-paginator>