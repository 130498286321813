import { createReducer, on, Action } from '@ngrx/store';
import { remove, setRole } from './roles.actions';
import { Role } from './roles.model';

export const roleFeatureKey = 'role';

export const initialState: Role = {
    role: '',
    restriction: [],
};

export const reducer = createReducer(
    initialState,
    on(setRole, (_, action: any) => {
        return { ...action.data };
    }),
    on(remove, () => {
        return initialState;
    })
);

export function roleReducer(state = initialState, action: any) {
    return reducer(state, action);
}