import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DefaultLayoutComponent } from './containers';
import { Page404Component } from './views/pages/page404/page404.component';
import { Page500Component } from './views/pages/page500/page500.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './views/pages/register/register.component';
import { PassbookListComponent } from './pages/user/components/passbook-list/passbook-list.component';

// Import guards
import { AuthGuard } from './core/guards/auth.guard';
import { Page403Component } from './pages/page403/page403.component';
import { AnnouncementComponent } from './pages/announcement/announcement.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { PurchaseTokenHistoryComponent } from './pages/launchpad/components/purchase-token-history/purchase-token-history.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    // canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'theme',
        loadChildren: () =>
          import('./views/theme/theme.module').then((m) => m.ThemeModule)
      },
      {
        path: 'base',
        loadChildren: () =>
          import('./views/base/base.module').then((m) => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () =>
          import('./views/buttons/buttons.module').then((m) => m.ButtonsModule)
      },
      {
        path: 'forms',
        loadChildren: () =>
          import('./views/forms/forms.module').then((m) => m.CoreUIFormsModule)
      },
      {
        path: 'charts',
        loadChildren: () =>
          import('./views/charts/charts.module').then((m) => m.ChartsModule)
      },
      {
        path: 'icons',
        loadChildren: () =>
          import('./views/icons/icons.module').then((m) => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./views/notifications/notifications.module').then((m) => m.NotificationsModule)
      },
      {
        path: 'widgets',
        loadChildren: () =>
          import('./views/widgets/widgets.module').then((m) => m.WidgetsModule)
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./views/pages/pages.module').then((m) => m.PagesModule)
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./pages/user/user.module').then((m) => m.UserModule)
      },
      {
        path: 'subadmin',
        loadChildren: () =>
          import('./pages/subadmin/subadmin.module').then((m) => m.SubadminModule),
        canActivate: [AuthGuard],
        data: {
          type: 'private',
          name: 'Sub Admin'
        }
      },
      {
        path: 'newsletter',
        loadChildren: () => import('./pages/subscriber/subscriber.module').then((m) => m.SubscriberModule),
        canActivate: [AuthGuard],
        data: {
          type: 'private',
          name: 'News Letter'
        }
      },
      {
        path: 'modules',
        loadChildren: () =>
          import('./pages/modules/modules.module').then((m) => m.ModulesModule),
        canActivate: [AuthGuard],
        data: {
          type: 'private',
          name: 'Modules'
        }
      },
      {
        path: 'submodules',
        loadChildren: () =>
          import('./pages/submodules/submodules.module').then((m) => m.SubmodulesModule),
        canActivate: [AuthGuard],
        data: {
          type: 'private',
          name: 'Sub Modules'
        }
      },
      {
        path: 'currency',
        loadChildren: () =>
          import('./pages/currency/currency.module').then((m) => m.CurrencyModule),
        canActivate: [AuthGuard],
        data: {
          type: 'private',
          name: 'Coin List'
        }
      },
      {
        path: 'passbook',
        component: PassbookListComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Passbook List',
          type: 'private',
          name: 'Passbook List'
        }
      },
      {
        path: 'emailtemplates',
        loadChildren: () =>
          import('./pages/emailtemplates/emailtemplates.module').then((m) => m.EmailtemplatesModule),
        canActivate: [AuthGuard],
        data: {
          type: 'private',
          name: 'Email template List'
        }
      },
      {
        path: 'cmspages',
        loadChildren: () =>
          import('./pages/cmspages/cmspages.module').then((m) => m.CmspagesModule),
        canActivate: [AuthGuard],
        data: {
          type: 'private',
          name: 'Cms List'
        }
      },
      {
        path: 'contact-us',
        loadChildren: () =>
          import('./pages/contact-us/contact-us.module').then((m) => m.ContactUsModule),
        canActivate: [AuthGuard],
        data: {
          type: 'private',
          name: 'Contact Us'
        }
      },
      {
        path: 'site-setting',
        loadChildren: () =>
          import('./pages/site-setting/site-setting.module').then((m) => m.SiteSettingModule),
        canActivate: [AuthGuard],
        data: {
          type: 'private',
          name: 'Site Setting'
        }
      },
      {
        path: '403',
        component: Page403Component,
        data: {
          title: 'Page 403',
        }
      },

      {
        path: 'profile',
        loadChildren: () =>
          import('./pages/profile/profile.module').then((m) => m.ProfileModule)
      },


      // {
      //   path: 'changepassword',
      //   loadChildren: () =>
      //     import('./pages/changepassword/changepassword.module').then((m) => m.ChangepasswordModule)
      // },
      {
        path: 'settings',
        loadChildren: () =>
          import('./pages/settings/settings.module').then((m) => m.SettingsModule)
      },
      {
        path: 'loginhistory',
        loadChildren: () =>
          import('./pages/loginhistory/loginhistory.module').then((m) => m.LoginhistoryModule)
      },


      {
        path: 'spottrade',
        loadChildren: () =>
          import('./pages/spottrade/spottrade.module').then((m) => m.SpottradeModule)
      },

      {
        path: 'transfer',
        loadChildren: () =>
          import('./pages/transferhistory/transferhistory.module').then((m) => m.TransferhistoryModule)
      },


      {
        path: 'priceconversion',
        loadChildren: () =>
          import('./pages/priceconversion/priceconversion.module').then((m) => m.PriceconversionModule)
      },

      {
        path: 'adminprofit',
        loadChildren: () =>
          import('./pages/adminprofit/adminprofit.module').then((m) => m.AdminprofitModule)
      },

      {
        path: 'launchpad',
        loadChildren: () =>
          import('./pages/launchpad/launchpad.module').then((m) => m.LaunchpadModule),
        canActivate: [AuthGuard],
        data: {
          type: 'private',
          name: 'Launchpad'
        }
      },
      {
        path: 'token-history',
        component: PurchaseTokenHistoryComponent,
        data: {
          title: 'Purchase Token History'
        }
      },
      {
        path: 'announcement',
        component: AnnouncementComponent,
        canActivate: [AuthGuard],
        data: {
          type: 'private',
          name: 'Announcement'
        }
      },
      {
        path: 'sliders',
        loadChildren: () =>
          import('./pages/slider/slider.module').then((m) => m.SliderModule),
        canActivate: [AuthGuard],
        data: {
          type: 'private',
          name: 'Sliders'
        }
      },
      {
        path: 'airdrop',
        loadChildren: () =>
          import('./pages/airdrop/airdrop.module').then((m) => m.AirdropModule),
        canActivate: [AuthGuard],
        data: {
          type: 'private',
          name: 'Airdrop List'
        }
      },
      {
        path: 'support-category',
        loadChildren: () =>
          import('./pages/support-category/support-category.module').then((m) => m.SupportCategoryModule),
        canActivate: [AuthGuard],
        data: {
          type: 'private',
          name: 'Support Category'
        }
      },
      {
        path: 'support-ticket',
        loadChildren: () =>
          import('./pages/support-ticket/support-ticket.module').then((m) => m.SupportTicketModule),
        canActivate: [AuthGuard],
        data: {
          type: 'private',
          name: 'Support Ticket'
        }
      }
    ]
  },
  {
    path: '404',
    component: Page404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: Page500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Login Page',
      type: 'auth'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Forgot Password',
      type: 'auth'
    }
  },
  {
    path: 'reset-password/:authToken',
    component: ResetPasswordComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Reset Password',
      type: 'auth'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
      // relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
