<c-row>
    <c-col xs="12">
        <c-card class="mb-4">
            <c-card-body>
                <form [formGroup]="form" (ngSubmit)="submitForm()">
                    <c-row class="mb-3">
                        <c-col  xs="12" md="6" class="mb-3 mb-md-0">
                            <label cLabel for="endDate">End Date</label>
                            <input type="date" aria-label="endDate" cFormControl formControlName="endDate"
                                (input)="onInputChange()" />
                            <span class="required" *ngIf="serverErrors?.endDate">{{ serverErrors.endDate }}</span>
                        </c-col>
                        <c-col  xs="12" md="6" class="mb-3 mb-md-0">
                            <label cLabel for="title">Title</label>
                            <input type="text" aria-label="title" cFormControl formControlName="title"
                                (input)="onInputChange()" />
                            <span class="required" *ngIf="serverErrors?.title">{{ serverErrors.title }}</span>
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <c-col xs>
                            <label cLabel for="content">Content</label>
                            <ckeditor name="myckeditor" required [config]="ckeConfig" debounce="500"
                                formControlName="content" (input)="onInputChange()">
                            </ckeditor>

                            <span class="required" *ngIf="serverErrors?.content">{{
                                serverErrors.content
                                }}</span>
                        </c-col>
                    </c-row>
                    <c-row>
                        <c-col [xs]="12">
                            <button cButton class="btn btn-primary" type="submit">
                                Submit
                            </button>
                        </c-col>
                    </c-row>
                </form>
            </c-card-body>
        </c-card>
    </c-col>
</c-row>
<div [innerHTML]="content"></div>