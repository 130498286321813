import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { navItems } from './_nav';

// Import selector
import { selectRole } from 'src/app/store/roles/roles.selectors';
import isEmpty from 'src/app/lib/validte';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent {

  public navitems: any;

  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  constructor(private store: Store) {

    store.select(selectRole).subscribe(e => {
      const { role, restriction } = e

      if (role == "superadmin") {
        this.navitems = navItems
      } else if (restriction && restriction.length > 0) {
        const nav = navItems.map((item: any) => {
          // console.log(item, 'item')

          if (item.children && item.children.length > 0) {
            const filteredChildren = item.children.filter((child: any) =>
              restriction.includes(child.name)
            );
            if (filteredChildren && filteredChildren.length > 0)
              return {
                ...item,
                children: filteredChildren,
              };
          } else {
            // console.log(item.name, restriction, restriction.includes(item.name), 'rest')
            return item.name === "Dashboard" || restriction.includes(item.name) ? item : null;
          }
        });
        this.navitems = nav.filter((item: any) => !isEmpty(item));
        // console.log(this.navitems, 'this.navitems')
      } else {
        const nv = navItems.filter((e) => e.name == 'Dashboard')
        this.navitems = nv
      }
    })
  }

  ngOnInit() { }
}
