<div class="table-responsive">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 responsive-table">

    <ng-container matColumnDef="createdAt" *ngIf="!isLoading">
      <th mat-header-cell *matHeaderCellDef class="cursor-pointer">
        <div class="header-name d-flex align-items-center" (click)="sortData('createdAt')">
          Created At
          <mat-icon class="sorting-icon" *ngIf="sorting.column === 'createdAt'">
            {{ sorting.direction === 1 ? 'arrow_upward' : 'arrow_downward' }}
          </mat-icon>
        </div>
        <mat-form-field>
          <input matInput [matDatepicker]="picker" (dateInput)="onDateChange($event, 'createdAt')"
            (dateChange)="onDateChange($event, 'createdAt')" placeholder="MM/DD/YYYY">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdAt | date:'medium' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="userCodeId" *ngIf="!isLoading">
      <th mat-header-cell *matHeaderCellDef class="cursor-pointer">
        <div class="header-name d-flex align-items-center" (click)="sortData('userCodeId')">
          User Id
          <mat-icon class="sorting-icon" *ngIf="sorting.column === 'userCodeId'">
            {{ sorting.direction === 1 ? 'arrow_upward' : 'arrow_downward' }}
          </mat-icon>
        </div>
        <mat-form-field appearance="outline" class="small-input" (input)="onInputChange($event, 'userCodeId')">
          <input matInput placeholder="Search" numbersOnly>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element"> {{element.userCodeId}} </td>
    </ng-container>

    <ng-container matColumnDef="tableId" *ngIf="!isLoading">
      <th mat-header-cell *matHeaderCellDef class="cursor-pointer">
        <div class="header-name d-flex align-items-center" (click)="sortData('tableId')">
          Reference ID
          <mat-icon class="sorting-icon" *ngIf="sorting.column === 'tableId'">
            {{ sorting.direction === 1 ? 'arrow_upward' : 'arrow_downward' }}
          </mat-icon>
        </div>
        <mat-form-field appearance="outline" class="small-input" (input)="onInputChange($event, 'tableId')">
          <input matInput placeholder="Search" alphaNumeric>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element"> {{element.tableId}} </td>
    </ng-container>

    <ng-container matColumnDef="coin" *ngIf="!isLoading">
      <th mat-header-cell *matHeaderCellDef class="cursor-pointer">
        <div class="header-name d-flex align-items-center" (click)="sortData('coin')">
          Coin
          <mat-icon class="sorting-icon" *ngIf="sorting.column === 'coin'">
            {{ sorting.direction === 1 ? 'arrow_upward' : 'arrow_downward' }}
          </mat-icon>
        </div>
        <mat-form-field appearance="outline" class="small-input" (input)="onInputChange($event, 'coin')">
          <input matInput placeholder="Search" alphaOnly>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element"> {{element.coin}} </td>
    </ng-container>

    <ng-container matColumnDef="beforeBalance" *ngIf="!isLoading">
      <th mat-header-cell *matHeaderCellDef class="cursor-pointer">
        <div class="header-name d-flex align-items-center" (click)="sortData('beforeBalance')">
          Before Balance
          <mat-icon class="sorting-icon" *ngIf="sorting.column === 'beforeBalance'">
            {{ sorting.direction === 1 ? 'arrow_upward' : 'arrow_downward' }}
          </mat-icon>
        </div>
        <mat-form-field appearance="outline" class="small-input" (input)="onInputChange($event, 'beforeBalance')">
          <input matInput placeholder="Search" numbersOnly>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element"> {{element.beforeBalance}} </td>
    </ng-container>

    <ng-container matColumnDef="afterBalance" *ngIf="!isLoading">
      <th mat-header-cell *matHeaderCellDef class="cursor-pointer">
        <div class="header-name d-flex align-items-center" (click)="sortData('afterBalance')">
          After Balance
          <mat-icon class="sorting-icon" *ngIf="sorting.column === 'afterBalance'">
            {{ sorting.direction === 1 ? 'arrow_upward' : 'arrow_downward' }}
          </mat-icon>
        </div>
        <mat-form-field appearance="outline" class="small-input" (input)="onInputChange($event, 'afterBalance')">
          <input matInput placeholder="Search" numbersOnly>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element"> {{element.afterBalance}} </td>
    </ng-container>

    <ng-container matColumnDef="amount" *ngIf="!isLoading">
      <th mat-header-cell *matHeaderCellDef class="cursor-pointer">
        <div class="header-name d-flex align-items-center" (click)="sortData('amount')">
          Amount
          <mat-icon class="sorting-icon" *ngIf="sorting.column === 'amount'">
            {{ sorting.direction === 1 ? 'arrow_upward' : 'arrow_downward' }}
          </mat-icon>
        </div>
        <mat-form-field appearance="outline" class="small-input" (input)="onInputChange($event, 'amount')">
          <input matInput placeholder="Search" numbersOnly>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
    </ng-container>

    <ng-container matColumnDef="type" *ngIf="!isLoading">
      <th mat-header-cell *matHeaderCellDef class="cursor-pointer">
        <div class="header-name d-flex align-items-center" (click)="sortData('type')">
          Type
          <mat-icon class="sorting-icon" *ngIf="sorting.column === 'type'">
            {{ sorting.direction === 1 ? 'arrow_upward' : 'arrow_downward' }}
          </mat-icon>
        </div>
        <mat-form-field>
          <mat-select appearance="outline" class="small-input" [(value)]="selectedType"
            (selectionChange)="onOptionChange($event, 'type')">
            <mat-option *ngFor="let option of typeOption" [value]="option">{{ option.replaceAll('_', ' ') | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element"> {{element.type.replaceAll('_', ' ') | titlecase}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{ 'hide-data': isLoading }"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9999" class="p-4">
        <strong class="text-secondary">No record found!</strong>
      </td>
    </tr>
  </table>
  <div *ngIf="isLoading" class="spinner-container">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
    </mat-progress-spinner>
  </div>
</div>
<mat-paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
  showFirstLastButtons aria-label="Select page of periodic elements" (page)="pageChanged($event)">
</mat-paginator>