import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// Import service
import { AnnouncementService } from './services/announcement.service'
import { ToastrService } from 'ngx-toastr';

// Import lib
import { decryptString } from 'src/app/lib/cryptoJs';
import  isEmpty  from 'src/app/lib/validte';


@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent {

  @ViewChild("myckeditor") ckeditor: any;

  ckeConfig: any;
  content: string;
  
  form: FormGroup;
  serverErrors: any;

  constructor(
    private _fb: FormBuilder,
    private announcementService: AnnouncementService,
    private toastr: ToastrService,
  ) {
    this.form = this._fb.group({
      title: [''],
      content: [null],
      endDate: ['']
    });
  }

  ngOnInit() {
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      removePlugins: 'exportpdf',
    };
  }

  submitForm() {
    const formValue = this.form.value
    let reqData = {
      endDate: !isEmpty(formValue.endDate) ? new Date(formValue.endDate).toISOString() : '',
      content: formValue.content,
      title: formValue.title
    }
    this.announcementService.create(reqData)
      .subscribe({
        next: (v: any) => {
          this.form.reset()
          this.serverErrors = {}
          this.toastr.success(v.message)
        },
        error: (err: any) => {
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
  }

  onInputChange() {
    this.serverErrors = {}
  }

}
