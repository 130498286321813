<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <c-container>
        <c-row class="justify-content-center">
            <c-col md="5">
                <img src="assets/logo.svg" class="img-fluid crediantialLogo" />
                <c-card-group>
                    <c-card class="p-4">
                        <c-card-body>
                            <form [formGroup]="form" (ngSubmit)="submitForm()">
                                <h1>Reset Password</h1>
                                <!-- <p class="text-medium-emphasis">Please enter your email you use to sign in to cred.</p> -->
                                <div class="mb-3">
                                    <c-input-group>
                                        <input [type]="showPassword ? 'text' : 'password'" autoComplete="off"
                                            cFormControl placeholder="Password" formControlName="password"
                                            (input)="onInputChange()" autoComplete="off" />

                                        <span cInputGroupText (click)="hanldeShowPassword('password')">
                                            <i [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                                        </span>
                                    </c-input-group>
                                    <span class="required"
                                        *ngIf="serverErrors?.password">{{serverErrors.password}}</span>
                                </div>
                                <div class="mb-3">
                                    <c-input-group>
                                        <input [type]="showConPassword ? 'text' : 'password'" autoComplete="off"
                                            cFormControl placeholder="Re-Enter Password" formControlName="confirmPassword"
                                            (input)="onInputChange()" autoComplete="off" />

                                        <span cInputGroupText (click)="hanldeShowPassword('con-password')">
                                            <i [ngClass]="showConPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                                        </span>
                                    </c-input-group>
                                    <span class="required"
                                        *ngIf="serverErrors?.confirmPassword">{{serverErrors.confirmPassword}}</span>
                                </div>
                                <c-row>
                                    <c-col xs="6">
                                        <button cButton class="px-4" color="primary" type="submit"
                                            [disabled]="isLoader">
                                            <span *ngIf="isLoader" class="spinner-grow spinner-grow-sm" role="status"
                                                aria-hidden="true"></span>
                                            Reset
                                        </button>
                                    </c-col>
                                    <!-- <c-col class="text-right" xs="6">
                                        <button cButton class="px-0 right-align" color="link"
                                            (click)="redirectToPage('/login')">
                                            Back to Login
                                        </button>
                                    </c-col> -->
                                </c-row>
                            </form>
                        </c-card-body>
                    </c-card>
                </c-card-group>
            </c-col>
        </c-row>
    </c-container>
</div>