import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from './icons/icon-subset';
import { Title } from '@angular/platform-browser';

// Import service
import { AuthService } from './pages/login/services/auth.service';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  title = 'Cred Bull';

  constructor(
    private router: Router,
    private titleService: Title,
    private iconSetService: IconSetService,
    private service: AuthService,
    private store: Store<any>,
    private cookieService: CookieService,
  ) {
    titleService.setTitle(this.title);
    // iconSet singleton
    iconSetService.icons = { ...iconSubset };
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
    this.adminData()
  }

  adminData(): void {
    const authToken = this.cookieService.get('admin_token')
    if (authToken) this.service.getAdminData()
  }
}
