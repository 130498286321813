<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <button
      toggle="visible"
      cHeaderToggler
      [cSidebarToggle]="sidebarId"
      class="ps-1"
    >
      <svg cIcon name="cilMenu" size="lg" width="16" height="16"></svg>
    </button>
    <!-- <c-header-nav class="d-none d-lg-flex me-auto">
      <c-nav-item>
        <a cNavLink routerLink="/dashboard" routerLinkActive="active">
          Dashboard
        </a>
      </c-nav-item>
      <c-nav-item>
        <a cNavLink routerLink="/users" routerLinkActive="active">Users</a>
      </c-nav-item>
      <c-nav-item>
        <a cNavLink routerLink="/settings" routerLinkActive="active">
          Settings
        </a>
      </c-nav-item>
    </c-header-nav> -->

    <!-- <c-header-nav class="d-none d-lg-flex">
      <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilBell" size="lg"></svg>
        </a>
      </c-nav-item>
      <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilList" size="lg"></svg>
        </a>
      </c-nav-item>
      <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilEnvelopeOpen" size="lg"></svg>
        </a>
      </c-nav-item>
    </c-header-nav> -->
    <c-header-nav class="ms-auto">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>
  </c-container>
  <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button cButton color="" [caret]="false" cDropdownToggle class="py-0">
      <c-avatar
        cTextColor="primary"
        shape="rounded-1"
        size="md"
        src="./assets/img/avatars/userImage.webp"
      >
      </c-avatar>
    </button>
    <ul cDropdownMenu class="pt-0 pr-5 w-auto">
      <!-- <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Account</h6>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilBell"></svg>
          Updates
          <c-badge class="ms-2 float-end" color="info">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilEnvelopeOpen"></svg>
          Messages
          <c-badge class="ms-2 float-end" color="success">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilTask"></svg>
          Tasks
          <c-badge class="ms-2 float-end" color="danger">
            42
          </c-badge>
        </a>
      </li>
      <li>



        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name='cilCommentSquare'></svg>
          Comments
          <c-badge class="ms-auto" color="warning">
            42
          </c-badge>
        </a>
      </li> -->
      <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Settings</h6>
      </li>

      <!-- <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilSettings"></svg>
          2FA Settings
        </a>
      </li> -->

      <li>
        <a routerLink="./profile" cDropdownItem>
          <svg cIcon class="me-2" name="cilSettings"></svg>
          Profile
        </a>
      </li>

      <li>
        <a routerLink="./loginhistory" cDropdownItem>
          <svg cIcon class="me-2" name="cilSettings"></svg>
          Login History
        </a>
      </li>
      <li>
        <a routerLink="./settings" cDropdownItem>
          <svg cIcon class="me-2" name="cilSettings"></svg>
          Settings
        </a>
      </li>

      <!-- <li>
        <a routerLink="./changepassword" cDropdownItem>
          <svg cIcon class="me-2" name="cilSettings"></svg>
          Change Password
        </a>
      </li> -->
      <!-- <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilFile"></svg>
          Projects
          <c-badge class="ms-2 float-end" color="primary">
            42
          </c-badge>
        </a>
      </li> -->
      <li>
        <hr cDropdownDivider />
      </li>
      <li>
        <span
          cDropdownItem
          class="d-flex align-items-center cursor-pointer"
          (click)="logOut()"
        >
          <i class="cil-account-logout pe-2"></i>
          Log out
        </span>
      </li>
      <li></li>
    </ul>
  </c-dropdown>
</ng-template>
