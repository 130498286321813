import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { remove } from 'src/app/store/roles';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent {

  @Input() sidebarId: string = "sidebar";

  public newMessages = new Array(4)
  public newTasks = new Array(5)
  public newNotifications = new Array(5)

  constructor(
    private classToggler: ClassToggleService,
    private cookieService: CookieService,
    private router: Router,
    private toastr: ToastrService,
    private store: Store
  ) {
    super();
  }

  logOut() {
    document.cookie = 'admin_token' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    this.store.dispatch(remove());
    this.router.navigateByUrl('/login')
    this.toastr.success('Logout Successfully')
  }
}
