import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

// Import selectors
import { selectRole } from 'src/app/store/roles/roles.selectors';

// Import lib
import { isLogin } from 'src/app/lib/cookies'
import { AuthService } from 'src/app/pages/login/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

// export const AuthGuard: CanActivateFn = (route, state) => {
//     const store: Store = inject(Store);
//     const router: Router = inject(Router);

//     store.select(selectRole).subscribe(e => {
//         const { role, restriction } = e;
//         const { type, name } = route.data;

//         let restrictData: string | boolean = 'loading'

//         if (restriction && restriction.length > 0) {
//             restrictData = restriction.includes(name)
//             console.log(restriction, 'restriction')
//         }

//         console.log(restriction, 'authguard')

//         if (type === 'auth' && isLogin() === true) {
//             router.navigateByUrl('/dashboard')
//         } else if (type === 'private' && isLogin() !== true) {
//             router.navigateByUrl('/login')
//         } else if (role !== 'superadmin' && restrictData === false) {
//             router.navigateByUrl('/403')
//         }
//     });
//     return true;
// };

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router: Router = inject(Router);
  const http = inject(HttpClient);
  const cookieService = inject(CookieService);

  const { type, name } = route.data;
  const isAuthenticated = cookieService.get('admin_token') ? true : false

  if (type === 'public') {
    return http.get(environment.userApiUrl + 'api/admin/getadmindetail').pipe(
      map((data: any) => {
        const { role, restriction } = data.result;
        let restrictData: string | boolean = 'loading'

        if (restriction && restriction.length > 0) {
          restrictData = restriction.includes(name)
        }

        if (name != 'Dashboard' && role !== 'superadmin' && restrictData === false) {
          router.navigateByUrl('/dashboard')
          return false;
        }

        return true
      })
    )
  } else {
    if (type === 'auth' && isAuthenticated === true) {
      router.navigateByUrl('/dashboard')
      return false;
    } else if (type === 'private' && isAuthenticated !== true) {
      router.navigateByUrl('/login')
      return false;
    }
    return true
  }
};