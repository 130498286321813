<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <c-container>
      <c-row class="justify-content-center">
        <c-col md="5">
          <img src="assets/logo.svg" class="img-fluid crediantialLogo" />
          <c-card-group>
            <c-card class="p-4">
              <c-card-body>
                <form [formGroup]="form" (ngSubmit)="submitForm()">
                  <h1>Forgot Password</h1>
                  <!-- <p class="text-medium-emphasis">Please enter your email you use to sign in to cred.</p> -->
                  <div class="mb-3">
                    <c-input-group>
                      <span cInputGroupText>
                        <svg cIcon name="cilUser"></svg>
                      </span>
                      <input autoComplete="off" cFormControl placeholder="Email" formControlName="email"
                        (input)="onInputChange()" />
                    </c-input-group>
                    <span class="required" *ngIf="serverErrors?.email">{{
                      serverErrors.email
                      }}</span>
                  </div>
                  <c-row>
                    <c-col xs="6">
                      <button cButton class="px-4" color="primary" type="submit" [disabled]="isLoader">
                        <span *ngIf="isLoader" class="spinner-grow spinner-grow-sm" role="status"
                          aria-hidden="true"></span>
                          Submit
                      </button>
                    </c-col>
                    <c-col class="text-right" xs="6">
                      <button cButton class="px-0 right-align" color="link" (click)="redirectToPage('/login')">
                        Back to Login
                      </button>
                    </c-col>
                  </c-row>
                </form>
              </c-card-body>
            </c-card>
          </c-card-group>
        </c-col>
      </c-row>
    </c-container>
  </div>