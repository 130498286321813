<!--<c-footer>-->
  <div>
    <span class="text-end"> &copy; 2023</span>
  </div>
  <!-- <div class="ms-auto">
    Powered by
    <a href="https://coreui.io/pro/angular" target="_blank">
      <span> CoreUI for Angular</span>
    </a>
  </div> -->
<!--</c-footer>-->
