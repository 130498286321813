import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

// Import service
import { ResetPasswordService } from './services/reset-password.service'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  isLoader: boolean = false
  form: FormGroup;
  serverErrors: any;
  showPassword: boolean = false
  showConPassword: boolean = false
  authToken: any = ''

  constructor(
    private service: ResetPasswordService,
    private toastr: ToastrService,
    private router: Router,
    private _fb: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this.form = this._fb.group({
      password: [''],
      confirmPassword: ['']
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.authToken = params.get('authToken');
    });
  }

  submitForm() {
    this.isLoader = true
    const reqBody = {
      ...this.form.value,
      authToken: this.authToken
    }

    this.service.resetPasswordApi(reqBody)
      .subscribe({
        next: (v: any) => {
          this.isLoader = false
          if (v.success) {
            this.router.navigateByUrl('/login')
            this.toastr.success(v.message)
            this.form.reset()
            this.serverErrors = {}
          }
        },
        error: (err: any) => {
          this.isLoader = false
          if (err.error.errors) {
            this.serverErrors = err.error.errors || {};
          }
          if (err.error.message) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
  }

  onInputChange() {
    this.serverErrors = {}
  }

  redirectToPage(str: string) {
    this.router.navigateByUrl(str)
  }
  hanldeShowPassword(type: string) {
    if (type == 'password')
      this.showPassword = !this.showPassword
    else if (type == 'con-password')
      this.showConPassword = !this.showConPassword
  }

}