import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Import environment
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';

// Import store
import { setRole } from 'src/app/store/roles';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private store: Store
  ) { }

  loginApi = (payload: any) => {
    return this.http.post(environment.userApiUrl + 'api/admin/login', payload)
  }

  resendOtpApi = (data: any) => {
    return this.http.post(environment.userApiUrl + 'api/admin/resendOtp', data)
  }

  getAdminData = () => {
    this.http.get(environment.userApiUrl + 'api/admin/getadmindetail')
      .subscribe({
        next: (v: any) => {
          console.log(v.result, 'v.result')
          console.log('api1')
          this.store.dispatch(setRole({ data: v.result }));
          console.log('api2')
        },
        error: () => { }
      })
  }

}
