<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <c-col md="5">
        <img src="assets/logo.svg" class="img-fluid crediantialLogo" />
        <c-card-group>
          <c-card class="p-4">
            <c-card-body>
              <form [formGroup]="form" (ngSubmit)="submitForm()">
                <h1>Login</h1>
                <p class="text-medium-emphasis">Sign In to your account</p>
                <div class="mb-3">
                  <c-input-group>
                    <!-- <span cInputGroupText>
                      <svg cIcon name="cilUser"></svg>
                    </span> -->
                    <input autoComplete="off" cFormControl placeholder="Email" formControlName="email"
                      (input)="onInputChange()" [readonly]="otpTextBox" />
                  </c-input-group>
                  <span class="required" *ngIf="serverErrors?.email">{{
                    serverErrors.email
                    }}</span>
                </div>
                <div class="mb-3">
                  <c-input-group>
                    <!-- <span cInputGroupText>
                      <svg cIcon name="cilLockLocked"></svg>
                    </span> -->
                    <input formControlName="password" autoComplete="off" cFormControl placeholder="Password"
                      [type]="showPassword ? 'text' : 'password'" (input)="onInputChange()" [readonly]="otpTextBox" />
                    <span cInputGroupText (click)="hanldeShowPassword()">
                      <i [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                    </span>
                  </c-input-group>
                  <span class="required" *ngIf="serverErrors?.password">{{
                    serverErrors.password
                    }}</span>
                </div>
                <!-- <div class="mb-3">
                  <c-input-group>
                    <input
                      formControlName="otp"
                      cFormControl
                      placeholder="Enter 6 digit OTP"
                      type="text"
                      (input)="onInputChange()"
                      [readonly]="otpVerified"
                    />
                    <span cInputGroupText>
                      <small>Resend Code</small>
                    </span>
                  </c-input-group>
                  <span class="required" *ngIf="serverErrors?.password">{{
                    serverErrors.password
                  }}</span>
                </div>
                <div class="mb-3">
                  <c-input-group>
                    <input
                      formControlName="twoFACode"
                      autoComplete="current-password"
                      cFormControl
                      placeholder="Enter 2FA Code"
                      type="twoFACode"
                      (input)="onInputChange()"
                    />
                  </c-input-group>
                  <span class="required" *ngIf="serverErrors?.otp">{{
                    serverErrors.otp
                  }}</span>
                </div> -->
                <div class="mb-3" *ngIf="otpTextBox">
                  <c-input-group>
                    <input formControlName="otp" cFormControl placeholder="Enter 6 digit OTP" type="text"
                      (input)="onInputChange()" [readonly]="otpVerified" />
                    <span cInputGroupText>
                      <svg cIcon name="cilCheck" *ngIf="otpVerified; else resend"></svg>
                      <ng-template #resend>
                        <span class="cursor-pointer" (click)="resendOtp()" *ngIf="!loaders; else timerInSec">
                          <small>Resend Code</small>
                        </span>
                      </ng-template>
                      <ng-template #timerInSec>
                        {{seconds}}
                      </ng-template>
                    </span>
                  </c-input-group>
                  <span class="required" *ngIf="serverErrors?.otp">{{serverErrors.otp}}</span>
                </div>
                <div class="mb-3" *ngIf="twoFATextBox">
                  <c-input-group>
                    <input formControlName="twoFACode" autoComplete="current-password" cFormControl
                      placeholder="Enter 2FA Code" type="twoFACode" (input)="onInputChange()" />
                  </c-input-group>
                  <span class="required" *ngIf="serverErrors?.twoFACode">{{serverErrors.twoFACode}}</span>
                </div>
                <c-row>
                  <c-col xs="12" class="text-center mb-3">
                    <button cButton class="px-4 w-100" color="primary" type="submit" [disabled]="isLoader">
                      <span *ngIf="isLoader" class="spinner-grow spinner-grow-sm" role="status"
                        aria-hidden="true"></span>
                      Login
                    </button>
                  </c-col>
                  <c-col class="text-center" xs="12">
                    <button cButton class="px-0 right-align" color="link" (click)="redirectToPage('/forgot-password')">
                      Forgot password?
                    </button>
                  </c-col>
                </c-row>
              </form>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div>