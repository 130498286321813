import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[email]'
})
export class EmailDirective {

    constructor(private _el: ElementRef) {
    }

    @HostListener('input', ['$event'])
    onInputChange(event: any) {
        // const initalValue = this._el.nativeElement.value;
        // this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9 @.]/, '');
        // if (initalValue !== this._el.nativeElement.value) {
        //     event.stopPropagation();
        // }
        const initialValue = this._el.nativeElement.value;
        const cleanedValue = initialValue.replace(/[^a-zA-Z0-9 @.]/g, '');

        // Handle consecutive dots by replacing with a single dot
        const cleanedAndHandledDots = cleanedValue.replace(/\.+/g, '.');

        if (cleanedAndHandledDots !== this._el.nativeElement.value) {
            this._el.nativeElement.value = cleanedAndHandledDots;
            event.stopPropagation();
        }
    }

}
