import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    // iconComponent: { name: 'cil-speedometer' },
  },
  {
    name: "User's Manage",
    url: '/user',
    // iconComponent: { name: 'cil-user' },
    children: [
      {
        name: 'User List',
        url: '/user/list'
      },
      {
        name: 'User Kyc',
        url: '/user/kyc'
      }
    ]
  },

  {
    name: "Spot Trade",
    url: '/spottrade',
    // iconComponent: { name: 'cil-user' },
    children: [
      {
        name: 'Pairs',
        url: '/spottrade/pairlist'
      },

      {
        name: 'Order History',
        url: '/spottrade/orderhistory'
      },

      {
        name: 'Trade History',
        url: '/spottrade/tradehistory'
      },

    ]
  },
  // {
  //   name: 'Trading Bot',
  //   url: '/tradingbot',
  //   // iconComponent: { name: 'cil-list' }
  // },

  // {
  //   title: true,
  //   name: 'Theme'
  // },
  {
    name: 'Passbook List',
    url: '/passbook',
    // iconComponent: { name: 'cil-list' }
  },
  {
    name: "Coin Details",
    url: '/currency',
    // iconComponent: { name: 'cil-user' },
    children: [
      {
        name: 'Coin List',
        url: '/currency'
      }
    ]
  },
  {
    name: "Transfer History",
    url: '/transfer',
    // iconComponent: { name: 'cil-user' },
    children: [
      {
        name: 'Deposit',
        url: '/transfer/deposit'
      },
      {
        name: 'Withdraw',
        url: '/transfer/withdraw'
      },

    ]
  },

  {
    name: 'Price Conversion',
    url: '/priceconversion'
  },


  {
    name: 'Admin Profit',
    url: '/adminprofit'
  },
  {
    name: 'Email Templates',
    url: '/emailtemplates'
  },
  {
    name: 'CMS Pages',
    url: '/cmspages'
  },
  {
    name: "Airdrop",
    url: '/airdrop',
    // iconComponent: { name: 'cil-user' },
    children: [
      {
        name: 'Airdrop',
        url: '/airdrop/list'
      },
      {
        name: 'Airdrop History',
        url: '/airdrop/history'
      },
    ]
  },
  // {
  //   name: 'Airdrop',
  //   url: '/airdrop/list'
  // },
  // {
  //   name: 'Airdrop History',
  //   url: '/airdrop/history'
  // },
  {
    name: 'Sliders',
    url: '/sliders'
  },
  {
    name: 'Contact Us',
    url: '/contact-us'
  },
  {
    name: 'Site Setting',
    url: '/site-setting'
  },
  {
    name: 'Announcement',
    url: '/announcement'
  },
  {
    name: 'Support Category',
    url: '/support-category'
  },
  {
    name: 'Support',
    url: '/support-ticket'
  },
  {
    name: 'Sub Admin',
    url: '/subadmin'
  },
  {
    name: 'News Letter',
    url: '/newsletter'
  },
  // {
  //   name: "Admin Controller",
  //   url: '/subadmin',
  //   // iconComponent: { name: 'cil-user' },
  //   children: [
  //     {
  //       name: 'Sub Admin',
  //       url: '/subadmin'
  //     },
  //     {
  //       name: 'Modules',
  //       url: '/modules'
  //     },
  //     {
  //       name: 'Sub Modules',
  //       url: '/submodules'
  //     },



  //   ]
  // },

  {
    name: "Launchpad",
    url: '/launchpad',
    // iconComponent: { name: 'cil-user' },
    children: [
      {
        name: 'Launchpad',
        url: '/launchpad'
      },
      {
        name: 'Token History',
        url: '/token-history'
      },
    ]
  },
  // {
  //   name: 'Typography',
  //   url: '/theme/typography',
  //   linkProps: { fragment: 'someAnchor' },
  //   iconComponent: { name: 'cil-pencil' }
  // },
  // {
  //   name: 'Components',
  //   title: true
  // },
  // {
  //   name: 'Base',
  //   url: '/base',
  //   iconComponent: { name: 'cil-puzzle' },
  //   children: [
  //     {
  //       name: 'Accordion',
  //       url: '/base/accordion'
  //     },
  //     {
  //       name: 'Breadcrumbs',
  //       url: '/base/breadcrumbs'
  //     },
  //     {
  //       name: 'Cards',
  //       url: '/base/cards'
  //     },
  //     {
  //       name: 'Carousel',
  //       url: '/base/carousel'
  //     },
  //     {
  //       name: 'Collapse',
  //       url: '/base/collapse'
  //     },
  //     {
  //       name: 'List Group',
  //       url: '/base/list-group'
  //     },
  //     {
  //       name: 'Navs & Tabs',
  //       url: '/base/navs'
  //     },
  //     {
  //       name: 'Pagination',
  //       url: '/base/pagination'
  //     },
  //     {
  //       name: 'Placeholder',
  //       url: '/base/placeholder'
  //     },
  //     {
  //       name: 'Popovers',
  //       url: '/base/popovers'
  //     },
  //     {
  //       name: 'Progress',
  //       url: '/base/progress'
  //     },
  //     {
  //       name: 'Spinners',
  //       url: '/base/spinners'
  //     },
  //     {
  //       name: 'Tables',
  //       url: '/base/tables'
  //     },
  //     {
  //       name: 'Tabs',
  //       url: '/base/tabs'
  //     },
  //     {
  //       name: 'Tooltips',
  //       url: '/base/tooltips'
  //     }
  //   ]
  // },
  // {
  //   name: 'Buttons',
  //   url: '/buttons',
  //   iconComponent: { name: 'cil-cursor' },
  //   children: [
  //     {
  //       name: 'Buttons',
  //       url: '/buttons/buttons'
  //     },
  //     {
  //       name: 'Button groups',
  //       url: '/buttons/button-groups'
  //     },
  //     {
  //       name: 'Dropdowns',
  //       url: '/buttons/dropdowns'
  //     },
  //   ]
  // },
  // {
  //   name: 'Forms',
  //   url: '/forms',
  //   iconComponent: { name: 'cil-notes' },
  //   children: [
  //     {
  //       name: 'Form Control',
  //       url: '/forms/form-control'
  //     },
  //     {
  //       name: 'Select',
  //       url: '/forms/select'
  //     },
  //     {
  //       name: 'Checks & Radios',
  //       url: '/forms/checks-radios'
  //     },
  //     {
  //       name: 'Range',
  //       url: '/forms/range'
  //     },
  //     {
  //       name: 'Input Group',
  //       url: '/forms/input-group'
  //     },
  //     {
  //       name: 'Floating Labels',
  //       url: '/forms/floating-labels'
  //     },
  //     {
  //       name: 'Layout',
  //       url: '/forms/layout'
  //     },
  //     {
  //       name: 'Validation',
  //       url: '/forms/validation'
  //     }
  //   ]
  // },
  // {
  //   name: 'Charts',
  //   url: '/charts',
  //   iconComponent: { name: 'cil-chart-pie' }
  // },
  // {
  //   name: 'Icons',
  //   iconComponent: { name: 'cil-star' },
  //   url: '/icons',
  //   children: [
  //     {
  //       name: 'CoreUI Free',
  //       url: '/icons/coreui-icons',
  //       badge: {
  //         color: 'success',
  //         text: 'FREE'
  //       }
  //     },
  //     {
  //       name: 'CoreUI Flags',
  //       url: '/icons/flags'
  //     },
  //     {
  //       name: 'CoreUI Brands',
  //       url: '/icons/brands'
  //     }
  //   ]
  // },
  // {
  //   name: 'Notifications',
  //   url: '/notifications',
  //   iconComponent: { name: 'cil-bell' },
  //   children: [
  //     {
  //       name: 'Alerts',
  //       url: '/notifications/alerts'
  //     },
  //     {
  //       name: 'Badges',
  //       url: '/notifications/badges'
  //     },
  //     {
  //       name: 'Modal',
  //       url: '/notifications/modal'
  //     },
  //     {
  //       name: 'Toast',
  //       url: '/notifications/toasts'
  //     }
  //   ]
  // },
  // {
  //   name: 'Widgets',
  //   url: '/widgets',
  //   iconComponent: { name: 'cil-calculator' },
  //   badge: {
  //     color: 'info',
  //     text: 'NEW'
  //   }
  // },
  // {
  //   title: true,
  //   name: 'Extras'
  // },
  // {
  //   name: 'Pages',
  //   url: '/login',
  //   iconComponent: { name: 'cil-star' },
  //   children: [
  //     {
  //       name: 'Login',
  //       url: '/login'
  //     },
  //     {
  //       name: 'Register',
  //       url: '/register'
  //     },
  //     {
  //       name: 'Error 404',
  //       url: '/404'
  //     },
  //     {
  //       name: 'Error 500',
  //       url: '/500'
  //     }
  //   ]
  // },
];
