import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Import environment
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LaunchpadService {

    constructor(private http: HttpClient) { }

    getAll = (params: any) => {
        return this.http.get(environment.launchpadApiUrl + 'api/admin/launchpad', { params })
    }

    getAllCurrency = () => {
        return this.http.get(environment.walletApiUrl + 'api/admin/getCurrency', {})
    }

    findById = (id: string) => {
        return this.http.get(environment.launchpadApiUrl + 'api/admin/edit-launchpad/' + id)
    }

    addLaunchpad = (data: any) => {
        return this.http.post(environment.launchpadApiUrl + 'api/admin/launchpad', data)
    }

    updateLaunchpad = (data: any) => {
        return this.http.put(environment.launchpadApiUrl + 'api/admin/launchpad', data)
    }

    purchaseTokenHistoryApi = (params: any) => {
        return this.http.get(environment.launchpadApiUrl + 'api/admin/purchaseToken', { params })
    }

}
